/*
 * Copyright 2018-2020 National Geographic Society
 *
 * Use of this software does not constitute endorsement by National Geographic
 * Society (NGS). The NGS name and NGS logo may not be used for any purpose without
 * written permission from NGS.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'redux-first-router';

import { QUERIES } from '@marapp/earth-shared';

import { useAuth0 } from '../../../auth/auth0';
import Layers from '../../../components/layers';
import Places from '../../../components/places';
import WidgetsComponent from '../../../components/widgets/component';
import WidgetSkeleton from '../../../components/WidgetSkeleton';
import { useDashboards, useLocation } from '../../../fetchers';
import { setLastViewedPlace } from '../../../modules/global/actions';
import { EMainType } from '../../../modules/global/model';
import { toggleLayer } from '../../../modules/layers/actions';
import { ILayer } from '../../../modules/layers/model';
import { setLocationHighlight, setMapBounds } from '../../../modules/map/actions';
import { setPlacesSearch } from '../../../modules/places/actions';
import { setSidebarInfo } from '../../../modules/sidebar/actions';
import { EPanels } from '../../../modules/sidebar/model';
import { IUrlCoordinates } from '../../../utils/map';

interface IProps {
  selected: boolean;
  panel: EPanels;
  activeLayers: ILayer[];
  slug?: string;
  organization?: string;
  initialUrlCoordinates: IUrlCoordinates;
  setSidebarInfo?: (payload: any) => void;
  toggleLayer?: (payload: any) => void;
  setMapBounds?: (payload: any) => void;
  setPlacesSearch?: (payload: any) => void;
  setLastViewedPlace?: (payload: any) => void;
  setLocationHighlight?: (payload: any) => void;
}

function WithData(props: IProps) {
  const {
    slug,
    selected,
    panel,
    organization,
    activeLayers,
    initialUrlCoordinates,
    setPlacesSearch,
    setLocationHighlight,
    setMapBounds,
    setLastViewedPlace,
  } = props;
  const { groups } = useAuth0();
  const { data: placeData, error } = useLocation(slug, QUERIES.LOCATION.getOne(organization));
  const { data: placeDataWithoutGeoJSON, revalidate } = useLocation(
    slug,
    QUERIES.LOCATION.getOneWithoutGeoJSON(organization)
  );
  const { data: dashboardsData } = useDashboards(QUERIES.DASHBOARD.getWithWidgets());
  const hasData = !!(placeDataWithoutGeoJSON && dashboardsData);

  useEffect(() => {
    if (!placeData) {
      return;
    }
    setPlacesSearch({ search: placeData.name });

    if (!initialUrlCoordinates) {
      setMapBounds({ bbox: placeData.bbox2d });
    }
    setLocationHighlight({
      id: placeData.id,
      geojson: placeData.geojson,
    });

    setLastViewedPlace({
      id: placeData.id,
      name: placeData.name,
      slug: placeData.slug,
      organization: placeData.organization,
      mainType: EMainType.LOCATION,
      subType: placeData.type,
    });

    // const isAnyMetricCalculating = placeData?.metrics.find((metric) => !!metric?.meta?.isCalculating);
    //
    // if (isAnyMetricCalculating) {
    //   const interval = setInterval(() => {
    //     revalidate();
    //   }, 3000);
    //
    //   setRevalidateInterval(interval);
    // }
    // else if (revalidateInterval) {
    //   clearInterval(revalidateInterval)
    //   setRevalidateInterval(null);
    // }
  }, [placeData]);

  useEffect(() => {
    const interval = setInterval(() => {
      revalidate();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [slug]);

  useEffect(() => {
    if (error) {
      console.error(error);
      if ([403, 404].includes(error.status)) {
        replace('/404');
      }
    }
  }, [error]);

  if (panel === EPanels.LAYERS) {
    return (
      <div style={{ display: 'none' }}>
        <Layers
          selected={selected}
          locationName={placeData?.name}
          locationOrganization={placeData?.organization}
        />
      </div>
    );
  }

  if (panel === EPanels.PLACES) {
    return (
      <div style={{ display: 'none' }}>
        <Places
          selected={selected}
          locationName={placeData?.name}
          locationOrganization={placeData?.organization}
        >
          {hasData ? (
            <div className="marapp-qa-indexsidebar">
              <div className="marapp-qa-indexcontent">
                <WidgetsComponent
                  {...props}
                  activeLayers={activeLayers}
                  dashboards={dashboardsData}
                  place={placeDataWithoutGeoJSON}
                  groups={groups}
                />
              </div>
            </div>
          ) : (
            <WidgetSkeleton />
          )}
        </Places>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    activeLayers: state.layers.active,
    slug: state.router.payload.slug,
    organization: state.router.payload.organization,
    initialUrlCoordinates: state.map.initialUrlCoordinates,
  }),
  {
    setSidebarInfo,
    toggleLayer,
    setMapBounds,
    setPlacesSearch,
    setLastViewedPlace,
    setLocationHighlight,
  }
)(WithData);
